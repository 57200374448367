import { useAuthStore } from '~/stores/auth'
import { useAlertStore } from '~/stores/alert'

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const { isSponsor, user, isAdmin, defaultUserPage } = useAuthStore()
  const alertStore = useAlertStore()

  if (isAdmin)
    return true

  if (!isSponsor) {
    alertStore.setAlert('You were redirected, because you are not authorized to view this page', 'error')
    return navigateTo(defaultUserPage)
  }

  if (user?.yearsInvited?.length) {
    if (user?.yearsInvited[0].accepted)
      return navigateTo(defaultUserPage)
  }
  return true
})
